import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { InputMask } from "primereact/inputmask";
import { Trans } from "react-i18next";
import { useConsultantRegister } from "../hooks/useGetDataQuery";
import { useToastContext } from "../Lib/Contexts/toastContext";

const Register = () => {
    const { t }: any = useOutletContext();
    const navigate = useNavigate();
    const { showToast } = useToastContext();
    
    const {
        mutate: consultantRegisterMutation,
        isPending: consultantRegisterIsPending,
        isSuccess: consultantRegisterIsSuccess,
        isError: consultantRegisterIsError,
        error: consultantRegisterError,
    } = useConsultantRegister();

    const [credentials, setCredentials] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        identityNumber: "",
    })
    const [acceptTerms, setAcceptTerms] = useState(false);

    const validateRegister = () => {
        return true;
    }

    const handleAPIRegister = () => {
        if(validateRegister()){
            consultantRegisterMutation(credentials);
        }
    }

    const handleCredentials = (e: any) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    }

    useEffect(() => {
        if(consultantRegisterIsSuccess){
            showToast({ severity: 'success', summary: 'Success', detail: "You're register request is applied!" });
            navigate({ pathname: '/' });
        }
    }, [consultantRegisterIsSuccess])

    useEffect(() => {
        if(consultantRegisterIsError){
            showToast({ severity: 'error', summary: 'Error', detail: consultantRegisterError });
        }
    }, [consultantRegisterIsError])
    
    return (
        <>       
            <p className='font-bold text-6xl m-0'><span style={{ color: "#2D3967" }}>{t("createAnAccount")}</span></p>
            <p className='text-4xl m-0' style={{ color: "#727A99" }}>{t('signUpToContinue')}</p>
            <div className='p-fluid w-full mt-6 mb-6'>
                <div className='flex flex-column gap-5 align-items-center mb-2'>
                    <div className="flex gap-3 w-full">
                        <div className='w-full'>
                            <FloatLabel>
                                <InputText name="firstName" className='p-inputtext-lg w-full' variant="filled" id='firstName' value={credentials.firstName} onChange={(e) => handleCredentials(e)} />
                                <label htmlFor='firstName'>{t('firstName')}</label>
                            </FloatLabel>
                        </div>
                        <div className='w-full'>
                            <FloatLabel>
                                <InputText name="lastName" className='p-inputtext-lg w-full' variant="filled" id='lastName' value={credentials.lastName} onChange={(e) => handleCredentials(e)} />
                                <label htmlFor='lastName'>{t('lastName')}</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className='w-full'>
                        <FloatLabel>
                            <InputText name="email" className='p-inputtext-lg w-full' variant="filled" id='email' value={credentials.email} onChange={(e) => handleCredentials(e)} />
                            <label htmlFor='email'>{t('usenameOrEmal')}</label>
                        </FloatLabel>
                    </div>
                    <div className="flex gap-3 w-full">
                        <div className='w-full'>
                            <FloatLabel>
                                <InputMask mask="(599) 999 99 99" name="phoneNumber" className='p-inputtext-lg w-full' variant="filled" id='phoneNumber' value={credentials.phoneNumber} onChange={(e) => handleCredentials(e)} />
                                <label htmlFor='phoneNumber'>{t('phoneNumber')}</label>
                            </FloatLabel>
                        </div>
                        <div className='w-full'>
                            <FloatLabel>
                                <InputText name="identityNumber" className='p-inputtext-lg w-full' variant="filled" id='identityNumber' value={credentials.identityNumber} onChange={(e) => handleCredentials(e)} />
                                <label htmlFor='identityNumber'>{t('identityNumber')}</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className='w-full'>
                        <FloatLabel>
                            <Password name="password" id="password" className='p-inputtext-lg w-full' toggleMask variant='filled' value={credentials.password} onChange={(e) => handleCredentials(e)} />
                            <label htmlFor="password">{t('password')}</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className='flex justify-content-between  mt-4'>
                    <div className="flex align-items-center">
                        <Checkbox name="pizza" value="Cheese" onChange={(e:any) => setAcceptTerms(e.checked)} checked={acceptTerms} />
                        <label className="ml-2" style={{ color: "#2D3967" }}>
                            <Trans i18nKey={"acceptTerms"} components={{ bold: <strong /> }} />
                        </label>
                    </div>
                </div>
                <Button onClick={handleAPIRegister} loading={consultantRegisterIsPending} style={{ backgroundColor: "#E3506B", borderRadius: "10px", border: "none" }} className='w-full mt-4' size="large" label={t('signUp')} severity='secondary' />
            </div>
            <div className='w-full flex justify-content-center'>
                <span className='font-medium' style={{color:"#2D3967"}}>
                    {t('alreadyHaveAnAccount')} 
                    <a href='/' style={{color:"#2D3967"}} className='font-semibold'>
                        {t('signIn')}
                    </a>
                </span>
            </div>
        </>   
    )
}

export default Register;