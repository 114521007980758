import { locale } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
    const [lang, setLang] = useState<any>();
    const { t, i18n } = useTranslation();

    const countries = [
        { name: 'EN', code: 'GB', lang: "en" },
        { name: 'TR', code: 'TR', lang: 'tr' },
    ];

    useEffect(() => {
        localStorage.getItem("i18nextLng") === "en" ? setLang({ name: 'EN', code: 'GB', lang: "en" })
            : setLang({ name: 'TR', code: 'TR', lang: 'tr' })
    }, []);

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang.lang)
            locale(lang.lang);
        }
    }, [lang]);

    const countryOptionTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    const selectedCountryTemplate = (option: any, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    return (
        <div style={{ backgroundColor: "#F5F5F5" }} className='w-full h-full'>
            <div className='flex flex-row'>
                <div className='w-10 overflow-hidden' style={{ height: "100vh" }}>
                    <img src="/signin-image.jpg" alt='Signing Image' width='100%' height='100%' style={{ objectFit: "cover" }} />
                </div>
                <div className='w-full flex flex-column justify-content-center align-items-center px-4'>
                    <div className='flex flex-column align-items-start w-8'>
                        <div className='flex w-full justify-content-end'>
                            <Dropdown value={lang} onChange={(e) => setLang(e.value)} options={countries} optionLabel="name"
                                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                                className='w-5rem' style={{ border: '1.5px solid #2D3967',backgroundColor:"#F5F5F5" }} />
                        </div>
                        <Outlet context={{ t }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthLayout;