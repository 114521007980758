import axios, { AxiosInstance } from "axios";

const baseURL = process.env.REACT_APP_API_DEV_BASE_URL

const api: AxiosInstance = axios.create({
    baseURL: baseURL,
})

export class Axios {
    public static setTokens(token: any, refreshToken: any) {
        Axios.setAuthToken(token);
        Axios.setRefreshToken(refreshToken);
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
    }
    public static setAuthToken(token: any) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    public static setRefreshToken(token: any) {
        api.defaults.headers.common['RefreshToken'] = `Bearer ${token}`;
    }

    public static getAuthToken() {
        return localStorage.getItem("token");
    }

    public static getRefreshToken() {
        return localStorage.getItem("refreshToken");
    }
}

export default api;
