import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { Password } from 'primereact/password';
import { useConsultantLogin } from '../hooks/useGetDataQuery';
import { useToastContext } from '../Lib/Contexts/toastContext';
import { Checkbox } from 'primereact/checkbox';

const Login = () => {
    const { showToast } = useToastContext();
    const [buttonClickForToast, setButtonClickForToast] = useState<any>(false)
    const [remember, setRemember] = useState<any>()

    const { t }: any = useOutletContext();

    const {
        mutate: consultantLoginMutation,
        isError: isErrorConsultantLogin,
        isSuccess: isSuccessConsultantLogin,
        error: consultantLoginError,
        isPending: isPendingConsultantLogin,
    } = useConsultantLogin();

    // const {
    //     mutate: loginWithAppleMutation,
    //     data: loginWithAppleData,
    //     isError: isErrorLoginWithApple,
    //     isSuccess: isSuccessLoginWithApple,
    //     error: loginWithAppleError,
    // } = useLoginWithApple();

    // const {
    //     mutate: loginWithGoogleMutation,
    //     data: loginWithGoogleData,
    //     isError: isErrorLoginWithGoogle,
    //     isSuccess: isSuccessLoginWithGoogle,
    //     error: loginWithGoogleError,
    // } = useLoginWithGoogle();

    const [formData, setFormData] = useState({
        username: '',
        password: ''
    })

    // useEffect(() => {
    //     if (buttonClickForToast) {
    //         showToast({ severity: 'success', summary: 'Success', detail: 'You have successfully logged in!' });
    //         setButtonClickForToast(false)
    //         validateLogin();
    //     }
    // }, [isSuccessLoginWithApple]);

    // useEffect(() => {
    //     if (buttonClickForToast) {
    //         showToast({ severity: 'error', summary: 'Error', detail: (loginWithAppleError as any)?.response.data.message });
    //         setButtonClickForToast(false)
    //     }
    // }, [isErrorLoginWithApple]);

    // useEffect(() => {
    //     if (buttonClickForToast) {
    //         showToast({ severity: 'success', summary: 'Success', detail: 'You have successfully logged in!' });
    //         setButtonClickForToast(false)
    //         validateLogin();
    //     }
    // }, [isSuccessLoginWithGoogle]);


    // useEffect(() => {
    //     if (buttonClickForToast) {
    //         debugger
    //         showToast({ severity: 'error', summary: 'Error', detail: (loginWithGoogleError as any)?.response.data.message });
    //         setButtonClickForToast(false)
    //     }
    // }, [isErrorLoginWithGoogle]);

    useEffect(() => {
        if (buttonClickForToast) {
            showToast({ severity: 'success', summary: 'Success', detail: 'You have successfully logged in!' });
            setButtonClickForToast(false)
            validateLogin();
        }
    }, [isSuccessConsultantLogin]);

    useEffect(() => {
        if (buttonClickForToast) {
            showToast({ severity: 'error', summary: 'Error', detail: (consultantLoginError as Error)?.message });
            setButtonClickForToast(false)
            validateLogin();
        }

    }, [isErrorConsultantLogin]);

    const navigate = useNavigate();

    const validateLogin = () => {
        navigate({ pathname: "/mobithera" });
    }

    // const handleContinueWithGoogle = useGoogleLogin({
    //     onSuccess: (tokenResponse: Omit<TokenResponse, "error" | "error_description" | "error_uri">) => {
    //         const requestBody: GoogleLoginRequest = {
    //             credentials: {
    //                 accessToken: tokenResponse.access_token
    //             },
    //             securityKey: "p#Tb4o34bgm7Lxe7y9M%JLQZWAR^L9QhRiNwRVUwHU9JvQmWB$y9p8i25HDbNRo$ofF7QbApB7AT@T22QqKiwV!tUBHqYd%4wkqWCfMxURVPJnpKv#Ur&G@o^WqR!n^k"
    //         }
    //         loginWithGoogleMutation(requestBody);
    //         setButtonClickForToast(true);
    //     },
    // });

    // const handleContinueWithApple = (response: AppleResponse) => {
    //     setButtonClickForToast(true);
    //     const requestBody: AppleLoginRequest = {
    //         credentials: {
    //             firstName: response?.user?.name.firstName,
    //             lastName: response?.user?.name.lastName,
    //             email: response?.user?.email,
    //             idToken: response?.authorization?.id_token
    //         },
    //         securityKey: "p#Tb4o34bgm7Lxe7y9M%JLQZWAR^L9QhRiNwRVUwHU9JvQmWB$y9p8i25HDbNRo$ofF7QbApB7AT@T22QqKiwV!tUBHqYd%4wkqWCfMxURVPJnpKv#Ur&G@o^WqR!n^k"
    //     }
    //     loginWithAppleMutation(requestBody);
    // }

    const checkCredentials = () => {
        if(formData.username === "" || formData.password === ""){
            return false;
        }
        return true;
    }

    const handleAPILogin = () => {
        if(!checkCredentials()) return;
        consultantLoginMutation(formData);
        setButtonClickForToast(true);
    }

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setFormData({
            ...formData,
            username: value
        })
    }

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setFormData({
            ...formData,
            password: value
        })
    }

    // const passwordArea = {
    //     passwordFooter: (
    //         <React.Fragment>
    //             <Divider />
    //             <p className='mt-2'>{t('suggestions')}</p>
    //             <ul className='pl-2 ml-2 mt-0' style={{ lineHeight: '1.5' }}>
    //                 <li>{t('lowerCase')}</li>
    //                 <li>{t('upperCase')}</li>
    //                 <li>{t('numeric')}</li>
    //                 <li>{t('minimum8')}</li>
    //             </ul>
    //         </React.Fragment>
    //     )
    // }

    // const appleRedirectURIHost = window.location.origin;

    return (
        <>       
            <p className='font-bold text-6xl m-0'><span style={{ color: "#2D3967" }}>{t("welcomeTo")}</span> <span style={{ color: "#E3506B" }}>MobiThera</span></p>
            <p className='text-4xl m-0' style={{ color: "#727A99" }}>{t('signInAccount')}</p>
            <div className='p-fluid w-full mt-6 mb-6'>
                <div className='flex flex-column gap-5 align-items-center mb-2'>
                    <div className='w-full'>
                        <FloatLabel>
                            <InputText className='p-inputtext-lg w-full' variant="filled" id='email' value={formData.username} onChange={(e) => handleEmail(e)} />
                            <label htmlFor='email'>{t('usenameOrEmal')}</label>
                        </FloatLabel>
                    </div>
                    <div className='w-full'>
                        <FloatLabel>
                            <Password feedback={false} id="password" className='p-inputtext-lg w-full' toggleMask variant='filled' value={formData.password} onChange={(e) => handlePassword(e)} />
                            <label htmlFor="password">{t('password')}</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className='flex justify-content-between  mt-4'>
                    <div className="flex align-items-center">
                        <Checkbox name="pizza" value="Cheese" onChange={e => setRemember(e.checked)} checked={remember} />
                        <label className="ml-2" style={{ color: "#2D3967" }}>{t('rememberMe')}</label>
                    </div>
                    <a href='#' style={{ color: "#64ADF0" }}>{t('forgotPass')}</a>
                </div>
                <Button loading={isPendingConsultantLogin} onClick={handleAPILogin} style={{ backgroundColor: "#E3506B", borderRadius: "10px", border: "none" }} className='w-full mt-4' size="large" label={t('signIn')} severity='secondary' />
            </div>
            {/*
            <div className='flex w-full justify-content-center align-items-center mb-2 mt-2'>
                <Divider />
                <span className='p-3' style={{ whiteSpace: "nowrap", color: "#727A99" }}> <span className='font-bold' style={{ color: "#2D3967" }}>{t("signInWithOther")}</span> {t("othersignIn")}</span>
                <Divider />
            </div>

            <div className='flex w-full flex-column gap-3 align-items-center mb-6'>
                <Button onClick={() => handleContinueWithGoogle()} className='w-full flex justify-content-center' style={{ backgroundColor: '#fff', border: '1.5px solid #2D3967', borderRadius: "10px" }} size="large">
                    <div className='flex gap-2 align-items-center'>
                        <img alt="google-logo" src="/social-media-google.png"></img>
                        <span className='font-semibold' style={{color:"#2D3967"}}>{t('google')}</span>
                    </div>
                </Button>
                <AppleSignin
                    authOptions={{
                        clientId: 'com.tiga.mobicareapp.web',
                        eslint-disable no-restricted-globals
                        redirectURI: `${appleRedirectURIHost}/callback`,
                        scope: 'name email',
                        usePopup: true,
                    }}
                    uiType="dark"
                    className="apple-auth-btn"
                    noDefaultStyle={false}
                    buttonExtraChildren="Continue with Apple"
                    onSuccess={(response: any) => handleContinueWithApple(response)}
                    onError={() => { }}
                    skipScript={false}
                    render={(props: any) => (
                        <Button {...props} className='w-full flex justify-content-center' style={{ backgroundColor: '#2D3967',border:"none",borderRadius:"10px" }} size="large">
                            <div className='flex gap-2 align-items-center'>
                                <img alt="google-logo" src="/social-media-apple.png"></img>
                                <span className='font-semibold' style={{color:"white"}}>{t('apple')}</span>
                            </div>
                        </Button>
                    )}
                />
            </div>*/}
            <div className='w-full flex justify-content-center'>
                <span className='font-medium' style={{color:"#2D3967"}}>{t('noAcc')}? <a href='/register' style={{color:"#2D3967"}} className='font-semibold'>{t('signUp')}</a></span>
            </div>
        </>         
    );
}

export default Login