import { Button } from "primereact/button";

const SelectedExercisesItemTemplate = ({exercise, handleDelete, t} : any) => {
    return (
        <div className="col-12 relative">
            <div className="flex flex-wrap justify-content-between align-items-center bg-white p-3 h-auto min-h-7rem" style={{ borderRadius: "18px" }}>
                <Button
                    onClick={() => handleDelete(exercise)}
                    className="absolute w-2rem h-2rem"
                    style={{ backgroundColor: "#E3506B", border: "none", top: "5px", right: "2px" }}
                    icon="pi pi-times"
                    rounded
                    aria-label="Delete"
                />
                <div className="flex-grow-1 flex-shrink-1 mr-2" style={{ maxWidth: '30%' }}>
                    <p className="text-lg font-bold m-0"
                        style={{ color: "#2D3967" }}>
                        {exercise.exercise.name}
                    </p>
                </div>
                <div className="flex gap-2 flex-grow-0 flex-shrink-1">
                    <div className="flex flex-column">
                        <span className="text-400 font-light text-md">{t("periods")}</span>
                        <span className="text-400 font-light text-md">{t("days")}</span>
                    </div>
                    <div className="flex flex-column min-w-0">
                        <span className="font-bold text-overflow-ellipsis overflow-hidden white-space-nowrap"
                            style={{ color: "#2D3967", maxWidth: "100px" }}>
                            {t(exercise.periods)}
                        </span>
                        <span className="font-bold text-overflow-ellipsis overflow-hidden white-space-nowrap"
                            style={{ color: "#2D3967", maxWidth: "100px" }}>
                            {exercise.days.map((day: any) => t(day)).join(", ")}
                        </span>
                    </div>
                </div>
                <div className="flex gap-2 flex-grow-0 flex-shrink-1">
                    <div className="flex flex-column">
                        <span className="text-400 font-light whitespace-nowrap text-md">{t("set")}, {t("reps")}</span>
                        <span className="text-400 font-light whitespace-nowrap text-md">Tol, {t("rest")}</span>
                    </div>
                    <div className="flex flex-column min-w-0">
                        <span className="font-bold text-overflow-ellipsis overflow-hidden"
                            style={{ color: "#2D3967" }}>
                            {exercise.set + " x " + exercise.repeat}
                        </span>
                        <span className="font-bold text-overflow-ellipsis overflow-hidden"
                            style={{ color: "#2D3967" }}>
                            {exercise.tolerance + "%, " + exercise.restTime + " " + t("secs")}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectedExercisesItemTemplate;