import { Card } from "primereact/card";
import { useEffect, useState } from "react"
import { Button } from "primereact/button";
import ExerciseList from "./exerciseList";
import BarList from "./barList";

const PlansCard = ({ t, planList, selectedPatient }: any) => {
    const lang = localStorage.getItem("i18nextLng") === "en" ? "en-US" : "tr-TR";

    const [planCount, setPlanCount] = useState<any>(0)

    const [planMetricIndex, setPlanMetricIndex] = useState<any>(0)
    const [selectedExercise, setSelectedExercise] = useState<any>()

    useEffect(() => {
        if (planList) {
            setSelectedExercise(planList[planCount]?.exerciseList[0])
        }
    }, [planList]);

    useEffect(() => {
        if (planCount) {
            setSelectedExercise(planList[planCount]?.exerciseList[0])
        }
    }, [planCount]);

    return (
        <div className="grid">
            <div className="col-3">
                <div className="flex flex-wrap justify-content-start align-items-center gap-2 ml-2 mb-3">
                    <p className="text-lg m-0 mr-4" style={{ color: "#727A99" }}>{planList[planCount]?.planName}</p>
                    <Button
                        disabled={planCount === 0}
                        className="planSwitchButton"
                        onClick={() => setPlanCount((prev: any) => prev - 1)}
                        icon="pi pi-angle-left"
                        style={{
                            borderRadius: "15px",
                            width: "1.5rem",
                            height: "1.5rem",
                            padding: "0",
                            background: "white",
                            border: "1px solid #2D3967",
                            color: "#2D3967"
                        }} />
                    <Button
                        disabled={planCount === planList.length - 1 || planList.length === 0}
                        icon="pi pi-angle-right"
                        className="planSwitchButton"
                        onClick={() => setPlanCount((prev: any) => prev + 1)}
                        style={{
                            borderRadius: "15px",
                            width: "1.5rem",
                            height: "1.5rem",
                            padding: "0",
                            background: "white",
                            border: "1px solid #2D3967",
                            color: "#2D3967"
                        }} />
                </div>
                <div style={{ height: "40rem", overflow: "auto" }}>
                    <ExerciseList
                        planList={planList}
                        planCount={planCount}
                        selectedExercise={selectedExercise}
                        setSelectedExercise={setSelectedExercise}>
                    </ExerciseList >
                </div>

            </div>
            <div className="col-9">
                <div className="flex flex-wrap justify-content-end align-items-center  mb-3">
                    <Button
                        text
                        onClick={() => setPlanMetricIndex(0)}
                        style={{
                            backgroundColor: "rgba(245, 245, 245)",
                            color: planMetricIndex === 0 ? "#2D3967" : "#9EA4B9",
                            height: "1.5rem",
                        }} >
                        <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                            {t("completion")}
                        </span>
                    </Button>
                    <Button
                        text
                        onClick={() => setPlanMetricIndex(1)}
                        style={{

                            backgroundColor: "rgba(245, 245, 245)",
                            color: planMetricIndex === 1 ? "#2D3967" : "#9EA4B9",
                            height: "1.5rem",
                        }} >
                        <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                            {t("success")}
                        </span>
                    </Button>
                </div>
                <Card style={{ borderRadius: "24px", position: "relative", height: "39.7rem" }}>
                    <BarList
                        lang={lang}
                        selectedExercise={selectedExercise}
                        planMetricIndex={planMetricIndex}
                        selectedPatient={selectedPatient}>
                    </BarList>

                </Card>
            </div>
        </div>
    )
}

export default PlansCard