import axios from "axios";
import { TResponse } from "./tresponse";
import { LazyParams } from "./lazyParams";
import { ApiLoginResponse } from "../Models/loginModels";
import api, { Axios } from "../axiosApi";

export class MobiTheraService {

  async tokenCheck(): Promise<any> {
    Axios.setAuthToken(Axios.getAuthToken())
    const tokenCheckResponse = await api.post("/Auth/TokenCheck")
    return tokenCheckResponse.data;
  }

  async getPatientList(lazyParamsForPatients: LazyParams): Promise<TResponse<any[]>> {
    const patientData = (await api.post("/Patient/GetAllCSR")).data;
    patientData["totalRecords"] = patientData?.totalCount;

    return patientData;
  }

  async addPatientToList(patient: any): Promise<any> {
    const patientCode = (await api.post("/PatientNew/Add", patient)).data;
    return patientCode
  }


  async getAppointmentList(lazyParamsForAppointments: LazyParams): Promise<TResponse<any[]>> {
    const appointmentData = (await api.post("/Appointment/GetAllCSR")).data;
    appointmentData["totalRecords"] = appointmentData?.totalCount;

    return appointmentData;
  }

  async updateAppointmentStatus(appointment: any): Promise<any> {
    await api.post("/Appointment/UpdateStatus", appointment);
  }

  async addPlan(plan: any): Promise<any> {
    return (await api.post("/Plan/Add", plan)).data;
  }

  async getPlanListById(patientId: any): Promise<TResponse<any[]>> {
    const obj = {
      patientId: patientId,
      status: "InProgress"
    }
    const planListData = (await api.post("/Plan/PlanListForPatinentPlanId", obj)).data;
    return planListData;
  }

  async getPlanStatusCount(): Promise<any> {
    const planCount = (await api.get("/Plan/PlanStatusCountByConsultant")).data
    return planCount;
  }

  async getExercisesList(lazyParamsForExercises: LazyParams): Promise<TResponse<any[]>> {
    const exercisesList = (await api.post("/ExerciseProcedure/GetAll", lazyParamsForExercises)).data.data;
    exercisesList["totalRecords"] = exercisesList?.count;
    return exercisesList;
  }

  async getCaruselLibraryExercises(): Promise<any> {
    const exercisesList = (await api.get("/ExerciseProcedure/GetCarouselList")).data.data
    return exercisesList;
  }

  async getMyPanels(): Promise<TResponse<any[]>> {
    const panelList = (await api.post("/ConsultantPanel/GetAll", {})).data.data;
    return panelList;
  }

  async saveMyPanel(panel: any): Promise<TResponse<any[]>> {
    const panelList = (await api.post("/ConsultantPanel/Add", panel)).data.data;
    return panelList
  }

  async deleteMyPanel(panelId: any): Promise<any> {
    await api.post("/ConsultantPanel/Delete", panelId);

  }


  async getGoogleCredentials(tokenResponse: any): Promise<any> {
    const googleApisResponse = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`, {
      headers: {
        Authorization: `Bearer ${tokenResponse.access_token}`,
        'Content-Type': 'application/json'
      }
    })
    const googleResponse = googleApisResponse.data;
    return googleResponse;
  }

  async consultantLogin(requestBody: any): Promise<ApiLoginResponse> {
    const consultantLoginResponse = await api.post(`/Keycloak/login`, requestBody)
    const consultantLoginData = consultantLoginResponse.data;

    localStorage.setItem('account', JSON.stringify(consultantLoginData.userInfo))

    Axios.setTokens(consultantLoginData.token.accessToken, consultantLoginData.token.refreshToken)

    return consultantLoginData;
  }

  async tokenCheckWithRefreshToken(): Promise<any> {
    Axios.setRefreshToken(Axios.getRefreshToken())
    Axios.setAuthToken(Axios.getAuthToken())
    const tokenCheckResponse = await api.post("/Keycloak/refresh-token", JSON.stringify(Axios.getRefreshToken()), { headers: { "Content-Type": "application/json" } });
    Axios.setTokens(tokenCheckResponse.data.accessToken, tokenCheckResponse.data.refreshToken)
    return tokenCheckResponse.data;
  }

  async consultantRegister(requestBody: any): Promise<any> {
    const consultantRegisterResponse = await api.post(`/SignUpRequests/Create`, requestBody)
    const consultantRegisterData = consultantRegisterResponse.data;

    return consultantRegisterData
  }

  async loginWithApple(requestBody: any): Promise<ApiLoginResponse> {
    const appleLoginResponse = await api.post(`/Auth/LoginWithApple`, requestBody)
    const appleLoginData = appleLoginResponse.data;

    localStorage.setItem('account', JSON.stringify(appleLoginData?.data.consultant))
    localStorage.setItem('token', appleLoginData.data.token)

    Axios.setAuthToken(appleLoginData.data.token)

    return appleLoginData;
  }

  async loginWithGoogle(requestBody: any): Promise<ApiLoginResponse> {
    const googleLoginResponse = await api.post(`/Auth/LoginWithGoogle`, requestBody)
    const googleLoginData = googleLoginResponse.data;

    localStorage.setItem('account', JSON.stringify(googleLoginData?.data.consultant))
    localStorage.setItem('token', googleLoginData.data.token)

    Axios.setAuthToken(googleLoginData.data.token)

    return googleLoginData;
  }

  async consultantGetCalismaSonuc(requestBody: any): Promise<any> {
    const result = await api.post(`/Consultant/GetCalismaSonuc`, requestBody)
    return result.data;
  }

  async consultantGetTahsilSonuc(requestBody: any): Promise<any> {
    const result = await api.post(`/Consultant/GetTahsilSonuc`, requestBody)
    return result.data;
  }

  async consultantGetUshHizmetiSonuc(requestBody: any): Promise<any> {
    const result = await api.post(`/Consultant/GetUshHizmetiSonuc`, requestBody)
    return result.data;
  }

  async getAccessCode(patientId: any): Promise<any> {
    const result = await api.post(`/AccessCodes/GetAccessCodes`, patientId)
    return result.data;
  }

  async submitAccessCode(patientObject: any): Promise<any> {
    const result = await api.post(`/AccessCodes/VerifyAccessCodes`, patientObject)
    return result.data;
  }

  async getSystemProcedures() : Promise<any> {
    return (await api.post("/SystemProcedure/GetAll", {filters: {name: {value: ""}}})).data.data
  }
}
