import { Skeleton } from "primereact/skeleton";

const SkeletonLoader = ({count}:any) => {
    return (
      <>
        {Array.from({ length: count }).map((_, index) => (
          <div
            key={index}
            style={{ width: "18rem" }}
          >
            <div
              className="flex m-1 pl-2 h-8rem justify-content-between gap-2"
              style={{
                backgroundColor: "#F5F5F5",
                borderRadius: "18px",
                boxShadow: "0 4px 4px -1px rgba(45, 57, 103, 0.05), 0 4px 10px -1px rgba(45, 57, 103, 0.1)",
                position: "relative"
              }}
            >
              <div className="flex flex-column justify-content-between gap-2 pt-4 pb-4">
                <Skeleton shape="circle" width="1.5rem" height="1.5rem" />
                <Skeleton width="8rem" height="2rem" className="mb-2" />
              </div>
              <div style={{ width: "55%", position: "relative", zIndex: 2 }}>
                <Skeleton width="100%" height="100%" borderRadius="18px" />
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "70%",
                  transform: "translate(-50%, -50%)",
                  width: "50%",
                  height: "100%",
                  zIndex: 1
                }}
              >
                <Skeleton shape="circle" width="100%" height="100%" />
              </div>
            </div>
          </div>
        ))}
      </>
    );
}

export default SkeletonLoader;